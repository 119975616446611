.shopItem {
  position: relative;
  max-width: 21.5rem;
}
.button {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  right: 0;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.title {
  max-width: 100%;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cardMedia {
  width: 18.5rem;
  max-height: 13.5rem;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 0.25rem 0.25rem 0 0;

  border: 1px solid #e9e9ea;
}

.link {
  font-weight: 600;
  text-wrap: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.description {
  max-width: 100%;
  text-wrap: nowrap;
  white-space: nowrap ;
  text-overflow: ellipsis;
  overflow: hidden;
}
