.reviewItem {
  position: relative;
}

.title {
  font-size: 16px;
  font-weight: 800;
}

.info {
  padding: 1.5rem;
}

.dataRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.description {
  height: 1.25rem;
  max-width: 100%;
  text-wrap: none;
  text-overflow: ellipsis;
  overflow: hidden;
}
.imagePreview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #828282;
  border-radius: 0.5rem;
  height: 10rem;
  background-color: #c4c4c4;
  overflow: hidden;
}
