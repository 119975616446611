.reviewItem {
  position: relative;
}

.title {
  font-size: 1rem;
  font-weight: 800;
}

.info {
  &:last-child {
    padding-bottom: 0;
  }
}

.dataRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.description {
  height: 1.25rem;
  max-width: 100%;
  text-wrap: none;
  text-overflow: ellipsis;
  overflow: hidden;
}
